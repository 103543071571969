
import { ISceneLoaderAsyncResult, Nullable, Scene, SceneLoader, AssetsManager, MeshBuilder } from "@babylonjs/core";
import { TextBlock } from "@babylonjs/gui";
import { Vector3 } from '@babylonjs/core/Maths/math';
import { Mesh } from "@babylonjs/core/Meshes/mesh";



/* eslint-disable no-var */
export class GLTFImportManager {

    loadAsset(scene: Scene, urlModel: string, onProgress: (event: any) => void, onComplete: (result: any) => void) {
        //this.disposeImportedObjects();
        // Names for the LODs.
        // scene.clearCachedVertexData();

        const lodNames = ["High", "Low", "Medium"];

        // The index of the next LOD to load.
        var lodNext = 0;
        // const _self = this;
        // Disable the loading screen since the UI text will show progress.
        SceneLoader.OnPluginActivatedObservable.addOnce(function (loader: any) {
            if (loader.name === "gltf") {
                loader.useRangeRequests = true;
            }
        });
        // Register for loader 
        SceneLoader.OnPluginActivatedObservable.addOnce(function (loader: any) {
            // This is just a precaution as this isn't strictly necessary since
            // the only loader in use is the glTF one.
            if (loader.name !== "gltf") return;

            // See what the loader is doing in the console.
            //   loader.loggingEnabled = true;

            // Use HTTP range requests to load the glTF binary (GLB) in parts.
            loader.useRangeRequests = true;

            // Register for when extension are loaded.
            loader.onExtensionLoadedObservable.add(function (extension: { name: string; onMaterialLODsLoadedObservable: any; }) {
                // Ignore extensions except MSFT_lod.
                if (extension.name !== "MSFT_lod") return;

                // Extensions are loaded after glTF has been parsed and
                // thus it is now loading the first LOD.

                // Update the status text and next LOD index when each set
                // of LODs are loaded.
                extension.onMaterialLODsLoadedObservable.add(function (index: number) {

                });

                // Uncomment the following line to stop at the specified LOD.
                //extension.maxLODsToLoad = 1;
            });

            // Update the status text when loading is complete, i.e. when
            // all the LODs are loaded.
            loader.onCompleteObservable.add(function () {

            });
        });

        // GLB asset with MSFT_lod, EXT_image_based_lighting, and range request support.
        // Asset created by https://twitter.com/VladimirStudio from Adobe.
        // const url = "../assets/" + this.nameModel;
        SceneLoader.ShowLoadingScreen = true;

        const obj = SceneLoader.ImportMeshAsync(null, "", urlModel, scene, function (event: any) {
            onProgress(event)
        })
        //this.rsvAsset = c

        obj.then(function (result: any) {
            onComplete(result)
        });
    }

    importByManager(scene: Scene, urlModel: string, position: Vector3, scale: Vector3, onComplete: (result: any) => void) {

        var assetsManager = new AssetsManager(scene);
        var meshTask = assetsManager.addMeshTask("skull task", "", "", urlModel)
        meshTask.onSuccess = function (task: any) {
            task.loadedMeshes[0].position = position;
            task.loadedMeshes[0].scaling = scale;
            // task.loadedMeshes[1].scaling = scale;
            console.log(task)
            //Simple invisble crate that acts as a hitbox to avoid expensive pixel-perfect collision
            var box = MeshBuilder.CreateBox("myBox", { height: 1.5, width: 0.50, depth: 1 }, scene);
            box.isVisible = false;
            box.setPositionWithLocalVector(new Vector3(0, 1, 0))
            box.showBoundingBox = true;
            box.parent = task.loadedMeshes[0];
            box.checkCollisions = true;
            onComplete(task.loadedMeshes[0])
        }

        meshTask.onError = function (msg: any) {
            console.log(msg)
        }
        assetsManager.load();

    }

}
