import { Camera, FreeCamera, FreeCameraMouseWheelInput, VRExperienceHelper } from "@babylonjs/core/Cameras";
import { Vector3 } from "@babylonjs/core/Maths";
import { Mesh } from "@babylonjs/core/Meshes";
import { Scene } from "@babylonjs/core/scene";


export class CameraManager {

    private _scene: Scene;
    private _canvas: HTMLCanvasElement;
    private _options: any;

    constructor(scene: Scene, canvas: HTMLCanvasElement, options :any) {
        this._scene = scene;
        this._canvas = canvas;
        this._options = options;
    }

    public createCamera(name: string, ground: Mesh, enableWebXR?: boolean | false,  ): Camera {
        const pos = new Vector3(this._options.initialPosition.x, this._options.initialPosition.y, this._options.initialPosition.z);
        const target = new Vector3(this._options.initialTarget.x, this._options.initialTarget.y, this._options.initialTarget.z)
        const camera = new FreeCamera(name, pos, this._scene);
        camera.setTarget(target)
        camera.inputs.addMouseWheel();
        (camera.inputs.attached.mousewheel as FreeCameraMouseWheelInput).wheelPrecisionY = this._options.wheelSpeed;
        (camera.inputs.attached.mousewheel as FreeCameraMouseWheelInput).wheelPrecisionX = this._options.wheelSpeed;
        camera.inputs.addKeyboard()
        camera.speed = 0.05;
        camera.checkCollisions = true;
        camera.ellipsoid = new Vector3(1, 1, 1);
        camera.angularSensibility = 6000;
        camera.speed = 0.01;
        camera.attachControl(this._canvas, true);
     
        if (enableWebXR)
            this.enableVXExperience(ground)
     
        // this.addOculus(this._scene);
        return camera;
    }

   
    public enableVXExperience(ground: Mesh) {

        // Enable VR
        const vrHelper: VRExperienceHelper = this._scene.createDefaultVRExperience({ createDeviceOrientationCamera: false, useXR: true });
      //  if(!vrHelper.teleportationEnabled)
        vrHelper.enableTeleportation({ floorMeshes: [ground] })

       
    }
}

