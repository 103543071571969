import { DirectionalLight } from "@babylonjs/core/Lights/directionalLight";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
import { Color3, PositionNormalVertex, Vector3 } from "@babylonjs/core/Maths";
import { Scene } from "@babylonjs/core/scene";


export class LightManager {

    private _scene: Scene;

    constructor(scene: Scene) {
        this._scene = scene;
        
    }

    createLight(name: string, lighttype: LightTypes, position: Vector3, intensity?: number, diffuse?: Color3 | undefined, specular?: Color3 | undefined) {
        let light = null;
        switch (lighttype) {
            case LightTypes.Directional:
                light = new DirectionalLight(name, position, this._scene);
                break;
            case LightTypes.Hemispheric:
                light = new HemisphericLight(name, position, this._scene);
                break;
            default:
                light = new DirectionalLight(name, position, this._scene);
                break;
        }

        light.diffuse =diffuse? diffuse : new Color3(1, 1, 1);
        light.specular = new Color3(0, 1, 0);
        light.intensity = 1;
        
        //this._scene.lights.push(light)
    }
}

export enum LightTypes {
    Directional,
    Hemispheric
}