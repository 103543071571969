import { ILoadingScreen } from "@babylonjs/core";

export class CustomLoadingScreen implements ILoadingScreen {
    //optional, but needed due to interface definitions
    
    constructor(public loadingUIText: string) { }
    loadingUIBackgroundColor!: string;

    public displayLoadingUI() {
        const elem: any = document.getElementById('imm-loading-canvas');
        elem.style.display = 'block';

        const elemCanvas: any = document.getElementById('imm-canvas-container');
        elemCanvas.style.display = 'none';
    }

    public hideLoadingUI() {
        const elem: any = document.getElementById('imm-loading-canvas');
        elem.style.display = 'none';

        const elemCanvas: any = document.getElementById('imm-canvas-container');
        elemCanvas.style.display = 'block';
    }
}


