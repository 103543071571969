
import Vue from 'vue';
import * as GUI from '@babylonjs/gui';
import "@babylonjs/loaders/glTF";
import { BoundingBoxGizmo, Engine, FreeCamera, HighlightLayer, PickingInfo, PointerEventTypes, SceneLoader } from "@babylonjs/core";
import { Scene } from "@babylonjs/core/scene";
import { GridMaterial, SkyMaterial } from "@babylonjs/materials";
import { GLTFImportManager } from "../core/loader/GLTFImportManager";
import { Vector3, Color3, Color4, Matrix } from '@babylonjs/core/Maths/math';
import { Mesh } from "@babylonjs/core/Meshes/";
import { PBRMaterial, StandardMaterial, VideoTexture } from "@babylonjs/core/Materials";
import { LightManager, LightTypes } from '@/core/lightManager';
import { TextBlock } from '@babylonjs/gui';
import { CustomLoadingScreen } from '@/core/loader/CustomLoaderScreen';
import * as CAMERA from "@babylonjs/core/Cameras";
import * as ANIM from "@babylonjs/core/Animations";

import selectTextureModel from '../components/dialogs/selectTextureModel.vue';
import cmpAssetPanel from './dialogs/cmpAssetPanel.vue';
import { CameraManager } from '@/core/windowEventManager';

export default Vue.extend({
    name: 'Viewer 3D',
    components: {
        selectTextureModel,
        cmpAssetPanel
    },
    data: () => ({
        interval: {},
        value: Object,
        hitedMesh: null,
        hilightMesh: null,
        isModelLoading: false,
        textureDialog: false,
        displayAssetPanel: false,
        currentPointerInfo: null,
        nameModel: String,
        mainScene: Scene,
        mainEngine: Engine,
        mainCanvas: HTMLCanvasElement,
        mainCamera: FreeCamera,
        mainGround: null,
        advancedTexture: null,
        time: performance.now(),
        frames: 0,
        myLight: null,
        rsvAsset: null,
        items: [
            { title: 'Z', cameraPosition: { x: 0, y: 30, z: 0 } },
            { title: 'Y', cameraPosition: { x: 0, y: 0, z: 30 } },
            { title: 'X', cameraPosition: { x: 30, y: 0, z: 0 } },
            { title: 'R', cameraPosition: { x: 0, y: 20, z: -40 } },
        ],
    }),
    computed: {
        currentEstateModel() {
            return this.$store.getters.currentEstate
        },
        loadingModelState() {

            return this.$store.getters.loadingModelState;
        }
    },
    watch: {
        "$store.state.loadingModelState": {
            handler: function (nv) {
                this.value = nv;
            },
            immediate: true // provides initial (not changed yet) state
        },

        "$store.state.currentEstate": {
            handler: function (nv) {
                if (nv == null) {
                    this.disposeScene()
                }

            },
            immediate: true // provides initial (not changed yet) state
        }
    },

    beforeMount() {
        this.isModelLoading = false;
        this.$store.commit('updateLoadingState', { progress: 0, state: 0 })

    },
    mounted() {
        this.initScene();
    },
    methods: {
        openPanel() {
            this.displayAssetPanel = true;
        },
        closePanel() {
            this.displayAssetPanel = false;
        },

        startDrag(evt, item) {

            evt.dataTransfer.dropEffect = 'move'
            evt.dataTransfer.effectAllowed = 'move'
            evt.dataTransfer.setData('itemID', item.id)
        },
        onDrop(evt, list) {
            console.log(this.currentPointerInfo)
            SceneLoader.ImportMesh('', "./assets_3D/apparts/sofa.glb", "", this.mainScene, function (container) {
                var gltfMesh = container[0]
                gltfMesh.position = this.currentPointerInfo.pickedPoint
                var bb = BoundingBoxGizmo.MakeNotPickableAndWrapInBoundingBox(gltfMesh)
                var gizmo = new BoundingBoxGizmo(Color3.FromHexString("#0984e3"))
                gizmo.ignoreChildren = true;
                gizmo.attachedMesh = bb;
            });
            const itemID = evt.dataTransfer.getData('itemID')
            const item = this.items.find((item) => item.id == itemID)
            item.list = list
        },
        changeTexture(name: string) {
            this.textureDialog = false;
            console.log(name)
            console.log(this.hitedMesh.material)
            this.hilightMesh.removeMesh(this.hitedMesh);
            if (name === undefined || name === "")
                return false;
            var pbr = new PBRMaterial("pbr", this.mainScene);
            this.hitedMesh.material._albedoTexture.updateURL(name);

            pbr.albedoColor = new Color3(1.0, 0.766, 0.336);
            pbr.metallic = 1.0; // set to 1 to only use it from the metallicRoughnessTexture
            pbr.roughness = 1.0; // set to 1 to only use it from the metallicRoughnessTexture
            //pbr.reflectionTexture = CubeTexture.CreateFromPrefilteredData("/textures/environment.dds", scene);

            pbr.useRoughnessFromMetallicTextureAlpha = false;
            pbr.useRoughnessFromMetallicTextureGreen = true;
            pbr.useMetallnessFromMetallicTextureBlue = true;

        },
        closeTextureDialog() {

            this.textureDialog = false;
            this.changeTexture("");
        },
        /** Init scene */
        initScene() {
            var loadingScreen = new CustomLoadingScreen("I'm loading!!");

            const canvas = document.getElementById("renderCanvas") as HTMLCanvasElement;
            var engine = new Engine(canvas);
            engine.resize(true);
            engine.loadingScreen = loadingScreen;
            // show the loading screen
            engine.displayLoadingUI();
            var scene = new Scene(engine);
            this.hilightMesh = new HighlightLayer("hlRed_Mesh", scene);
            scene.gravity = new Vector3(0, -0.9, 0);
            scene.collisionsEnabled = true;
            scene.clearColor = Color4.FromColor3(Color3.Black());

            // Sky material
            var skyboxMaterial = new SkyMaterial("skyMaterial", scene);
            skyboxMaterial.backFaceCulling = false;
            //skyboxMaterial._cachedDefines.FOG = true;

            // Sky mesh (box)
            var skybox = Mesh.CreateBox("skyBox", 1000.0, scene);
            skybox.material = skyboxMaterial;
            var setSkyConfig = function (property, from, to) {
                var keys = [
                    { frame: 0, value: from },
                    { frame: 100, value: to }
                ];

                var animation = new ANIM.Animation("animation", property, 100, ANIM.Animation.ANIMATIONTYPE_FLOAT, ANIM.Animation.ANIMATIONLOOPMODE_CONSTANT);
                animation.setKeys(keys);

                scene.stopAnimation(skybox);
                scene.beginDirectAnimation(skybox, [animation], 0, 100, false, 1);
            };
            // Set to Day
            setSkyConfig("material.inclination", skyboxMaterial.inclination, 0);
            //scene.createDefaultLight(true);
            ///scene.createDefaultEnvironment();

            // var envMgr = new EnvironmentManager();
            //envMgr.createDefaultSkyBox(engine, scene, "
            //  
            this.initLights(scene);
            //scene.createDefaultLight();
            this.initGround(scene);

            this.$store.commit('updateLoadingState', { progress: 100, state: 0 })
            this.mainScene = scene;
            this.mainEngine = engine;
            this.mainCanvas = canvas;
         
            this.initCamera(canvas,scene)
            //this.addRotater(this.mainScene);
            ////////CONTROL ENGINE LOOP///////////
            this.mainEngine.runRenderLoop(() => {
                this.mainScene.render();
            });
            engine.hideLoadingUI();
            // this.importModel();
           
            this.importModelByManager();
            
         // this.importModelByManager();
         
        },

        /** Init scene lights */
        initLights(scene: Scene) {
            let lightMgr = new LightManager(scene);
            lightMgr.createLight("DirectionalLightTop", LightTypes.Directional, new Vector3(0, -30, 0));
            lightMgr.createLight("DirectionalLightRight", LightTypes.Directional, new Vector3(32, 30, 5));
            lightMgr.createLight("DirectionalLightLeft", LightTypes.Directional, new Vector3(-30, -10, 0));
            lightMgr.createLight("light1", LightTypes.Hemispheric, new Vector3(0, -30, 10));
            // lightMgr.createLight("light1", LightTypes.Hemispheric, new Vector3(0, 10, -10));

        },

        /** Init scene main ground */
        initGround(scene: Scene) {
            var ground = Mesh.CreatePlane("ground", 700, scene);
            ground.material = new GridMaterial("groundMat", scene);
            (ground.material as GridMaterial).mainColor = new Color3(0, 0, 0);
            ground.material.backFaceCulling = false;
            ground.position = new Vector3(5, 0, -15);
            ground.rotation = new Vector3(Math.PI / 2, 0, 0);
            ground.checkCollisions = true;
            this.mainGround = ground
        },
        /** Init main scene camera */
        initCamera(canvas: any, scene: Scene) {
            let options = this.currentEstateModel.config3D.camera
            let mgr = new CameraManager(scene, this.mainCanvas, options);
            this.mainCamera = mgr.createCamera("Camera1",this.mainGround, true) as CAMERA.FreeCamera;
            this.configMovement(options)
        },

        configMovement( options: any) {
            var camera: FreeCamera= this.mainCamera as FreeCamera;
            const distance: number = options.moveDistance
         
            window.addEventListener("keydown", (e) => {
                //if left arrow is pressed
                console.log(window)
                switch (e.keyCode) {
                    case 39:
                    camera.cameraDirection.addInPlace(camera.getDirection(Vector3.Right()).scale(distance));
                        break;
                    case 37:
                    camera.cameraDirection.addInPlace(camera.getDirection(Vector3.Left()).scale(distance));
                        break;
                    case 38:
                    camera.cameraDirection.addInPlace(camera.getDirection(Vector3.Forward()).scale(distance));
                        break;
                    case 40:
                    camera.cameraDirection.addInPlace(camera.getDirection(Vector3.Backward()).scale(distance));
                        break;
                }
                switch (e.key) {
                    case "p":
                    case "P":
                    camera.cameraDirection.addInPlace(camera.getDirection(Vector3.Up()).scale(distance));
                        break
                    case "m":
                    case "M":
                    camera.cameraDirection.addInPlace(camera.getDirection(Vector3.Down()).scale(distance));
                        break
                }
            });
        },

        /** configure camera keybord inputs */

        /** import 3D model */
        importModel() {
            var mgr = new GLTFImportManager();
            //const _self = this;
            var scene: Scene = this.mainScene;
            const [topLine, bottomLine] = this.createUITexture();
            const model = this.currentEstateModel;
            //mgr.importByManager(scene,'https://dev-apps.immobalcaen.be/Apparts/001-Appart/EstateMetaVerse.glb')
            mgr.loadAsset(scene, model.config3D.urlModel,
                (event: any) => {
                    let lodNext = 0;
                    // Compute the percentage for each stage unless the length is not computable.
                    // The lengthComputable is often false when serving content that is gzipped.
                    const percentage = event.lengthComputable ? " " + Math.floor(event.loaded / event.total * 100) + "%" : "";
                    this.$store.commit('updateLoadingState', { progress: percentage, state: 0 })
                    // c.ShowLoadingScreen = true;
                    // Check if an LOD is loading yet.
                    topLine.text = "";
                    if (lodNext === null) {
                        // Ignore GLB header progress.
                        if (event.total === 20) return;
                        // Show that the glTF is downloading.
                        bottomLine.text = "Loading 3D Model..." + percentage;
                    } else {
                        // Show that the LOD is downloading.
                        bottomLine.text = "Loading 3D model progress : " + percentage;
                    }

                }, (result) => {
                    console.log(result)
                    bottomLine.text = "";
                    var mat = new StandardMaterial("Material_Video_Tele", scene);
                    var videoTexture = new VideoTexture(
                        "video",
                        ["http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4", "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4"],
                        scene,
                        false,
                        false,
                        VideoTexture.TRILINEAR_SAMPLINGMODE, {
                            autoPlay: false,
                            autoUpdateTexture: true
                        }
                    );
                    mat.diffuseTexture = videoTexture;
                    ((mat.diffuseTexture) as VideoTexture).uScale = 1;

                });
        },

        /** import 3D model */
        importModelByManager() {
            const _self = this;
            var mgr = new GLTFImportManager();
            var scene: Scene = _self.mainScene;
            var config = _self.currentEstateModel.config3D
            mgr.importByManager(scene, config.urlModel, new Vector3(0, 0, 0), new Vector3(config.scaling.x, config.scaling.y, config.scaling.z),()=>{
                
                scene.onPointerObservable.add((pointerInfo) => {
                switch (pointerInfo.type) {
                    case PointerEventTypes.POINTERDOWN:
                    _self.currentPointerInfo = pointerInfo
                        break;
                    case PointerEventTypes.POINTERUP:
                        console.log("POINTER UP");
                        break;
                    case PointerEventTypes.POINTERMOVE:
                        console.log("POINTER MOVE");
                        break;

                    case PointerEventTypes.POINTERTAP:
                        console.log("POINTER TAP");
                        var ray = scene.createPickingRay(scene.pointerX, scene.pointerY, Matrix.Identity(), this.mainCamera);
                        var hit = scene.pickWithRay(ray) as PickingInfo;
                        if (hit.pickedMesh) {
                            // _self.createGUIButton(_self.advancedTexture)
                            console.log(hit.pickedMesh)
                            var editMeshesList = _self.currentEstateModel.config3D.editableMeshes;
                            editMeshesList.map((m: any) => {
                                if (m.name === hit.pickedMesh.name) {
                                    _self.hitedMesh = hit.pickedMesh
                                    _self.hilightMesh.addMesh(_self.hitedMesh, Color3.Red());
                                    _self.textureDialog = true;
                                    // hit.pickedMesh.material =  
                                }
                            })

                            //mat//new Texture("https://playground.babylonjs.com/scenes/Alien/Alien_baseColor.png", scene, null, false);
                        }
                        break;
                    case PointerEventTypes.POINTERDOUBLETAP:
                        console.log("POINTER DOUBLE-TAP");
                        break;
                }
            });

            })
            //mgr.importByManager(scene,'sofa.glb', new Vector3(0,0,0), new Vector3(1,1,1))

        },

        /** set camera position */
        moveCamera(item: any) {
            console.log(item)
            var config = this.currentEstateModel.config3D
            this.mainCamera.position = new Vector3(config.camera.initialPosition.x, config.camera.initialPosition.y, config.camera.initialPosition.z)
            this.mainCamera.target = new Vector3(config.camera.initialTarget.x, config.camera.initialTarget.y, config.camera.initialTarget.z)
        },


        /** create texture for import  */
        createUITexture(): Array < TextBlock > {
            // Create full screen UI.
            const uiTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");

            // Create attribution line of text.
            const attributionLine = new GUI.TextBlock();
            attributionLine.color = "white";
            attributionLine.fontSize = 18;
            attributionLine.top = "-45%";
            attributionLine.text = this.currentEstateModel.name;
            uiTexture.addControl(attributionLine);

            // Create top line of text.
            const topLine = new GUI.TextBlock();
            topLine.color = "white";
            topLine.fontSize = 24;
            topLine.top = "-38%";
            uiTexture.addControl(topLine);

            // Create bottom line of text.
            const bottomLine = new GUI.TextBlock();
            bottomLine.highlightColor = "white"
            bottomLine.color = "#67d408";
            bottomLine.fontSize = 22;
            bottomLine.top = "45%";
            uiTexture.addControl(bottomLine);

            return [topLine, bottomLine];
        },

        /** dispose meshes scene and engine */
        disposeScene() {
            let _scene: Scene = this.mainScene as unknown as Scene;
            let _engine: Engine = this.mainEngine as unknown as Engine;
            for (let i = 0; i < _scene.cameras.length; i++) {
                if (_scene.cameras[i] && !_scene.cameras[i].isDisposed()) {
                    _scene.cameras[i].dispose(false, true);
                    _scene.cameras[i] = null;
                }
            }
            for (let i = 0; i < _scene.lights.length; i++) {
                if (_scene.lights[i] && !_scene.lights[i].isDisposed()) {
                    _scene.lights[i].dispose(false, true);
                    _scene.lights[i] = null;
                }
            }
            for (let i = 0; i < _scene.materials.length; i++) {
                if (_scene.materials[i]) {
                    _scene.materials[i].dispose();
                    _scene.materials[i] = null;
                }
            }
            for (let i = 0; i < _scene.meshes.length; i++) {
                if (_scene.meshes[i] && !_scene.meshes[i].isDisposed()) {
                    _scene.meshes[i].dispose(false, true);
                    _scene.meshes[i] = null;
                }
            }
            for (let i = 0; i < _scene.spriteManagers.length; i++) {
                if (_scene.spriteManagers[i]) {
                    _scene.spriteManagers[i].dispose();
                    _scene.spriteManagers[i] = null;
                }
            }
            for (let i = 0; i < _scene.textures.length; i++) {
                if (_scene.textures[i]) {
                    _scene.textures[i].dispose();
                    _scene.textures[i] = null;
                }
            }
            _scene.resetDrawCache();
            _scene.dispose();
            _engine.dispose();
        },

    }
})
